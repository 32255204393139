<template>
	<div class="banner">
		<div class="banner-content">
			By continuing to use this website, you consent to our use of cookies as outlined in our
			<a href="https://assets.stanwith.me/legal/privacy-policy.pdf">Cookie Policy</a>.
		</div>
		<div class="buttons">
			<div class="banner-button" @click="acceptCookies">
				<div class="banner-button-content">Accept</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CookieBanner',
		setup(props, { emit }) {
			function declineCookies() {
				console.log('declineCookies')
				emit('declineCookies')
			}
			function acceptCookies() {
				console.log('acceptCookies')
				emit('acceptCookies')
			}
			return {
				declineCookies,
				acceptCookies,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.banner {
		position: fixed;
		bottom: 20px;
		width: calc(100% - 70px);
		left: 20px;
		border-radius: 20px;
		z-index: 999;
		font-family: Inter, sans-serif;
		font-size: 12px;
		background-color: #ffffff;
		box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
		padding: 15px;
		text-align: center;

		@media (min-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 50%;
			left: 50%;
			transform: translate(-50%);
		}

		.banner-content {
			padding: 10px;
			padding-bottom: 20px;
			@media (min-width: 768px) {
				padding-bottom: 10px;
			}

			a {
				text-decoration: underline;
			}
		}

		.buttons {
			display: flex;
			gap: 20px;
			align-items: center;
			justify-content: center;
		}

		.banner-button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 40px;
			border: none;
			background-color: #131f60;
			border-radius: 50px;
			box-shadow: 0 40px 40px rgba(236, 223, 223, 0.1);
			animation: 1s slide-in 0.2s ease forwards;
			transition: 0.3s ease;
			cursor: pointer;

			&:hover {
				background: #090e2a;
			}

			.banner-button-content {
				padding: 10px;
				padding-bottom: 12px;
				font-family: 'Plus Jakarta Sans', sans-serif;
				font-weight: 600;
				font-size: 14px;
				color: #ffffff;
			}
		}
	}
</style>
