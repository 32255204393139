<template>
	<div class="layout">
		<s-dialog
			v-model="showWatchDemoDlg"
			class="scrollTarget"
			:transition-duration="300"
			:no-backdrop-dismiss="false"
			:auto-close="false"
			@dialog-show="cmdShowWatchDemoDialogShow"
		>
			<YouTubeVideo video-id="Gwm_Odue2gc" @close="cmdCloseRegisterDlg"></YouTubeVideo>
		</s-dialog>
		<CookieConsentBanner v-if="showCookieConsentBanner" @acceptCookies="acceptCookies" @declineCookies="declineCookies" />
		<router-view />
	</div>
</template>

<script>
	import { defineComponent, provide, ref, getCurrentInstance, onMounted, onBeforeMount, onUpdated, computed, nextTick } from 'vue'
	import { useFlowControlsStore } from './stores/flowControls'
	import { ready } from '@/plugins/dom-events'
	import { useCookies } from 'vue3-cookies'
	import { stanAnalytics } from '@/plugins/stan-analytics'
	import { AdsTracking } from '@/plugins/ads-tracking'
	import { SDialog } from '@/components/SDialog'
	import YouTubeVideo from '@/components/YouTubeVideo'
	import CookieConsentBanner from '@/components/utils/CookieConsentBanner'
	import { trackSessionVariables } from '@/plugins/feature-flags'

	export default defineComponent({
		name: 'App',
		components: {
			SDialog,
			YouTubeVideo,
			CookieConsentBanner,
		},
		setup() {
			const { cookies } = useCookies()
			const {
				proxy: { $emitter },
			} = getCurrentInstance()
			const x = trackSessionVariables()
			const showCookieConsentBanner = x.isFlag('cookie_consent_banner_visible') && ref(!cookies.get('cookies_consent_accepted'))

			if (!showCookieConsentBanner) {
				AdsTracking('Initialize')
			}

			const showWatchDemoDlg = ref(false)

			const fcStore = useFlowControlsStore()

			const registerUrl = computed(() => {
				return `${process.env.VUE_APP_ADMIN_URL}/register?signupFlow=default-modal`
			})

			ready(async () => {
				fcStore.$patch({ fcpComplete: true })
			})

			const registerEvent = section => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section,
				}
				stanAnalytics('landing-registration-trigger', { meta: { username: 'guest' }, props })

				const urlString = window.location.href
				const url = new URL(urlString)
				const param = url.searchParams.get('ref')
				if (param) {
					window.location.href = `${process.env.VUE_APP_ADMIN_URL}/register?ref=${param}`
				} else {
					window.location.href = `${process.env.VUE_APP_ADMIN_URL}/register`
				}
				return false
			}

			const signIn = section => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section,
				}
				stanAnalytics('landing-signin-trigger', { meta: { username: 'guest' }, props })
				const urlString = window.location.href
				const url = new URL(urlString)
				const param = url.searchParams.get('ref')
				if (param) {
					window.location.href = `${process.env.VUE_APP_ADMIN_URL}/?ref=${param}`
				} else {
					window.location.href = `${process.env.VUE_APP_ADMIN_URL}`
				}
				return false
			}

			const registerByUrl = msg => {
				register(msg)
			}

			function cmdCloseRegisterDlg() {
				showWatchDemoDlg.value = false
			}

			const stanAnalyticsCall = async (name, section) => {
				const props = {
					pageVersion: cookies.get('landingVersion'),
					section: section,
				}
				stanAnalytics(name, { meta: { username: 'guest' }, props })
			}

			function cmdWatchDemoVideo(section) {
				showWatchDemoDlg.value = true
				stanAnalyticsCall('landing-play-demo', section)
			}

			function cmdHomeVideoPlaying(section) {
				stanAnalyticsCall('landing-video-playing', '')
			}

			function cmdCloseWatchDemoVideo() {
				showWatchDemoDlg.value = false
			}

			const cmdShowWatchDemoDialogShow = async msg => {}

			const innerWidth = ref(0)
			const innerHeight = ref(0)

			function windowResize() {
				innerWidth.value = document.body.clientWidth
				innerHeight.value = document.body.clientHeight
			}

			// Added Status page
			const statusPage = document.createElement('script')
			statusPage.setAttribute('src', 'https://status.stan.store/embed/script.js')
			document.head.appendChild(statusPage)

			onMounted(() => {
				windowResize()
				$emitter.on('register', registerEvent)
				$emitter.on('sign.in', signIn)
				$emitter.on('home.video.playing', cmdHomeVideoPlaying)
				$emitter.on('watch.demo.video', cmdWatchDemoVideo)
				window.addEventListener('resize', () => windowResize())
				window.addEventListener(
					'message',
					event => {
						if (event.origin === process.env.VUE_APP_ADMIN_URL && event.data.user_registered) {
							window.location.href = process.env.VUE_APP_ADMIN_URL
						}
					},
					false
				)
			})
			onBeforeMount(() => {
				$emitter.off('register', registerEvent)
				$emitter.off('sign.in', signIn)
				$emitter.off('watch.demo.video', cmdWatchDemoVideo)
				window.removeEventListener('resize', () => windowResize())
			})
			function acceptCookies() {
				AdsTracking('Initialize')
				stanAnalytics('cookie-consent-banner-accepted', { meta: { username: 'guest' } })
				cookies.set('cookies_consent_accepted', true, '-1')
				showCookieConsentBanner.value = false
			}
			function declineCookies() {
				showCookieConsentBanner.value = false
			}

			return {
				showWatchDemoDlg,
				registerUrl,
				cmdCloseWatchDemoVideo,
				cmdShowWatchDemoDialogShow,
				cmdCloseRegisterDlg,
				showCookieConsentBanner,
				acceptCookies,
				declineCookies,
			}
		},
	})
</script>
<style lang="postcss">
	@import '@/assets/styles/bootstrap.min.css';
	@import '@/assets/styles/main.css';

	.layout {
		font-family: 'Grobek', sans-serif;
		background-size: cover;
	}
</style>
